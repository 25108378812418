
<template>
  <div>
    <b-button
      v-if="!noBtn"
      variant="info"
      size="sm"
      class="mr-2"
      @click="toggleModal"
    >
      {{ $t('buttons.reset_password')}}
    </b-button>
    <b-modal
      v-model="isResetPasswordModalActive"
      :title="$t('buttons.reset_password')"
      hide-footer
    >
      <ValidationObserver
        ref="resetPasswordForm"
        v-slot="{ handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(onSubmitResetPassword)">
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('user.new_password')"
            rules="required"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="`${$t('user.new_password')} *`"
              label-for="reset_password"
            >
              <b-input-group>
                <b-form-input
                  id="reset_password"
                  v-model="resetPasswordData.password"
                  type="text"
                  :state="errors[0] ? false : null"
                  autocomplete="off"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </ValidationProvider>
          <div class="text-right">
            <b-button
              variant="light"
              class="mr-2"
              @click="isResetPasswordModalActive = false"
            >
              {{ $t('buttons.cancel')}}
            </b-button>
            <b-overlay
              :show="loading"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('buttons.confirm')}}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    noBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowPassword: false,
      isResetPasswordModalActive: false,
      resetPasswordData: {
        password: '',
      },
    }
  },
  methods: {
    toggleModal() {
      this.isResetPasswordModalActive = !this.isResetPasswordModalActive
    },
    onClose() {
      this.isResetPasswordModalActive = false
    },
    onSubmitResetPassword() {
      this.$emit('reset-password', this.resetPasswordData)
    },
  },
}
</script>
